import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['entity_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
      };
    }

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let bodyParams = [params].map((farm) => {
      let crops = farm.crops
        // .filter((crop) => !!crop.operation_enum)
        .map((crop) => {
          crop['harvest_date'] = dayjs(crop['harvest_date']).format('YYYY-MM-DDTHH:mm:ss');

          return {
            person_id: _.get(window, 'user.userid') || 0,
            person_address_id: 0,
            status_enum: true,
            operation_enum: 1,
            ...crop,
            crop_area: crop.crop_area ? parseInt(crop.crop_area) : 0,
          };
        });

      return {
        ...farm,
        crops,
      };
    });

    bodyParams = bodyParams.map((farm) => {
      return {
        farm_address: {
          person_address_id: 0,
          person_id: _.get(window, 'user.userid') || 0,
          registered_address_flag: true,
          address_lattitude: '',
          address_longitude: '',
          landmark: '',
          district_id: 0,
          state_id: 0,
          country_id: 0,
          is_transaction_address: true,
          acreage: 1,
          created_by: 1,
          created_datetime: '2021-01-04T06:00:10.823Z',
          modified_by: 0,
          modified_datetime: '2021-01-04T06:00:10.823Z',
          lock_id: 0,
          location_id: 0,
          taluka_id: 0,
          village_id: 0,
          name_of_farm: '',
          address_line1: '',
          address_line2: '',
          address_line3: '',
          pin_code: '',
          id: 1,
          person_code: '',
          offset_day_type_enum_code: '',
          location_code: '',
          country_code: '',
          state_code: '',
          district_code: '',
          taluka_code: '',
          village_code: '',
          address_type_enum_code: '',
          ...farm,
          city_id: 0,
          city_code: '',

          size_of_farm: parseInt(_.get(farm, 'size_of_farm') || 0),
          status_enum: true,
          address_type_enum: 3,
          type_of_individual_enum: 1,
          address_text: _.get(farm, 'name_of_farm') || '',
          location_tuple: _.get(farm, 'location_code') || '',
        },
        crop_details: farm.crops,
        operation_enum: 1,
      };
    });

    return bodyParams;
  },

  update(id, params) {
    let bodyParams = [params].map((farm) => {
      let crops = farm.crops
        // .filter((crop) => !!crop.operation_enum)
        .map((crop) => {
          return {
            person_id: _.get(window, 'user.userid') || 0,
            person_address_id: id,
            status_enum: true,
            operation_enum: 3,
            ...crop,
            crop_area: crop.crop_area ? parseInt(crop.crop_area) : 0,
          };
        });

      return {
        ...farm,
        crops,
      };
    });

    bodyParams = bodyParams.map((farm) => {
      return {
        farm_address: {
          person_address_id: id,
          person_id: _.get(window, 'user.userid') || 0,
          registered_address_flag: true,
          address_lattitude: '',
          address_longitude: '',
          landmark: '',
          district_id: 0,
          state_id: 0,
          country_id: 0,
          is_transaction_address: true,
          acreage: 1,
          created_by: 1,
          created_datetime: '2021-01-04T06:00:10.823Z',
          modified_by: 0,
          modified_datetime: '2021-01-04T06:00:10.823Z',
          lock_id: 0,
          location_id: 0,
          taluka_id: 0,
          village_id: 0,
          name_of_farm: '',
          address_line1: '',
          address_line2: '',
          address_line3: '',
          pin_code: '',
          id: 1,
          person_code: '',
          offset_day_type_enum_code: '',
          location_code: '',
          country_code: '',
          state_code: '',
          district_code: '',
          taluka_code: '',
          village_code: '',
          address_type_enum_code: '',
          ...farm,
          city_id: 0,
          city_code: '',

          size_of_farm: parseInt(_.get(farm, 'size_of_farm') || 0),
          status_enum: true,
          address_type_enum: 3,
          type_of_individual_enum: 1,
          address_text: _.get(farm, 'name_of_farm') || '',
          location_tuple: _.get(farm, 'location_code') || '',
        },
        crop_details: farm.crops,
        operation_enum: 3,
      };
    });

    return bodyParams;
  },
  destroy(id) {
    const qsParams = {
      person_address_id: id,
    };

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: _.get(d, 'farm_address.person_address_id') || 0,
        ...(_.get(d, 'farm_address') || {}),
        name_of_farm: _.get(d, 'farm_address.address_text') || '',
        crops: _.get(d, 'crop_details') || [],
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchDetails(resData) {
    let data = resData || {};

    data = {
      id: _.get(data, 'farm_address.person_address_id') || 0,
      ...(_.get(data, 'farm_address') || {}),
      crops: _.get(data, 'crops') || [],
    };

    return data;
  },
  create(resData) {
    return resData;
  },
  update(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },
};
