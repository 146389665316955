import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const getAllEquipments = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/GetAllEquipments`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetAllEquipments');
    throw errors;
  }
};

export const getEquipmentMasterProfile = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/GetEquipmentMasterProfile`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetEquipmentMasterProfile');
    throw errors;
  }
};

export const verifyEquipmentProfile = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/EquipmentDetails/VerifyEquipmentProfile?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'VerifyEquipmentProfile');
    throw errors;
  }
};

export const getEquipmentRentalPaymentDetails = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/GetEquipmentRentalPaymentDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item) => ({ ...item, id: item.commodity_deal_master_id }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetEquipmentRentalPaymentDetails');
    throw errors;
  }
};

export const payEquipmentRentalPayment = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/PayEquipmentRentalPayment?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'PayEquipmentRentalPayment');
    throw errors;
  }
};

export const fetchAllRentalTransactions = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/FetchAllRentalTransactions`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item) => ({ ...item, id: item.commodity_deal_master_id }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllRentalTransactions');
    throw errors;
  }
};

export const updateTransportDealToEquipmentDeal = async (id, params) => {
  try {
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportDeal/UpdateTransportDealToEquipmentDeal?Transport_Deal_Master_Id=${id}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateTransportDealToEquipmentDeal');
    throw errors;
  }
};
