import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const LocationSchema = yup.object().shape({
  address_type_enum: yup.number().required('Please select Location Type').positive('Please select Location Type'),
  company_affiliate_enum: yup.number(),
  entity_id: yup.number().positive('Please select Business entity').required(),
  // gstin: yup.string().required('GSTIN is required'),
  // location_id: yup.number().required(),
  // location_code: yup.string(),
  // village_id: yup.number(),
  village_code: yup.string(),
  // taluka_id: yup.number(),
  taluka_code: yup.string(),
  // city_id: yup.number(),
  // city_code: yup.string(),
  // district_id: yup.number(),
  district_code: yup.string(),
  // state_id: yup.number(),
  state_code: yup.string(),
  country_id: yup.number(),
  country_code: yup.string(),
  address_lattitude: yup.string(),
  address_longitude: yup.string(),
  location_name: yup.string().required('Location Name is required'),
  // location_id: yup.number().typeError('Location_id required').positive('Location required').required(),
  address_line1: yup.string().required('Address 1 is a required field'),
  address_line2: yup.string(),
  address_line3: yup.string(),
  pin_code: yup.string().required('Pin Code is a required field'),
  location_code: yup.string().required('Location is a required field'),
});

export const GSTINSchema = yup.object().shape({
  address_type_enum: yup.number().required('Please select Location Type').positive('Please select Location Type'),
  company_affiliate_enum: yup.number(),
  entity_id: yup.number().positive('Please select Business entity').required(),
  // gstin: yup.string().required('GSTIN is required'),
  // location_id: yup.number().required(),
  // location_code: yup.string(),
  // village_id: yup.number(),
  village_code: yup.string(),
  // taluka_id: yup.number(),
  taluka_code: yup.string(),
  // city_id: yup.number(),
  // city_code: yup.string(),
  // district_id: yup.number(),
  district_code: yup.string(),
  // state_id: yup.number(),
  state_code: yup.string(),
  country_id: yup.number(),
  country_code: yup.string(),
  address_lattitude: yup.string(),
  address_longitude: yup.string(),
  location_name: yup.string().required('Location Name is required'),
  // location_id: yup.number().typeError('Location_id required').positive('Location required').required(),
  address_line1: yup.string().required('Address 1 is a required field'),
  address_line2: yup.string(),
  address_line3: yup.string(),
  pin_code: yup.string().required('Pin Code is a required field'),
  location_code: yup.string().required('Location is a required field'),
  gstin: yup
    .string()
    .required('GSTIN is a required field')
    .matches(
      /^(?=.*[A-Z])[0-9]{2}(?:[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}|(?:[A-Z]){5}\d{4}(?:[A-Z]){1}(?:\d|Z){1}(?:[A-Z]){1}(?:\d|Z){1})$/,
      'Invalid GST number',
    ),
});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchBusinessAndAffiliateLocationsAgainstEntityId = async (params = {}) => {
  try {
    let res = await api.fetchBusinessAndAffiliateLocationsAgainstEntityId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCompanyAndAffiliateLocations = async (params = {}) => {
  try {
    let res = await api.fetchCompanyAndAffiliateLocations(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    if (params?.acceptTerms === true) {
      await helpers.validateSchema(LocationSchema, params);
    } else {
      await helpers.validateSchema(GSTINSchema, params);
    }
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params) => {
  try {
    if (params?.acceptTerms === true) {
      await helpers.validateSchema(LocationSchema, params);
    } else {
      await helpers.validateSchema(GSTINSchema, params);
    }
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchTransportList = async (params = {}) => {
  try {
    let res = await api.fetchTransportList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getStorageLocations = async (params = {}) => {
  try {
    let res = await api.getStorageLocations(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchFarmerList = async (params = {}) => {
  try {
    let res = await api.fetchFarmerList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchInvoiceLocationList = async (params = {}) => {
  try {
    let res = await api.fetchInvoiceLocationList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getBankNames = async (params = {}) => {
  try {
    let res = await api.getBankNames(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getBankDetailsAgainstName = async (params = {}) => {
  try {
    let res = await api.getBankDetailsAgainstName(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const verifyBankAccount = async (params = {}) => {
  try {
    let res = await api.verifyBankAccount(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const kycVerification = async (params = {}) => {
  try {
    let res = await api.kycVerification(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getFacilitators = async (params = {}) => {
  try {
    let res = await api.getFacilitators(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchPersonalDetailsForFacilitator = async (person_id) => {
  try {
    let res = await api.FetchPersonalDetailsForFacilitator(person_id);
    return res;
  } catch (err) {
    throw err;
  }
};
