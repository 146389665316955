import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchAllInvoiceDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchScheduleAgainstCommodityDealMasterId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoiceDetails');
    throw errors;
  }
};
export const fetchAllInvoiceDetailsReceiver = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchAllInvoiceDetails_Receiver`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoiceDetails_Receiver');
    throw errors;
  }
};
export const recordPaymentByBuyerSeller = async (params) => {
  try {
    const bodyParams = reqTransform.recordPaymentByBuyerSeller(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/RecordPaymentByBuyerSeller`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.recordPaymentByBuyerSeller(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RecordPaymentByBuyerSeller');
    throw errors;
  }
};
export const recordPaymentByBuyerSellerMultiple = async (params) => {
  try {
    const bodyParams = reqTransform.recordPaymentByBuyerSeller(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/RecordPaymentByBuyerSeller_Multiple`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    // return resTransform.recordPaymentByBuyerSeller(resData);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RecordPaymentByBuyerSeller_Multiple');
    throw errors;
  }
};
export const fetchAllInvoiceDetails_ForPayouts = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchAllInvoiceDetails_ForPayouts`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item, i) => ({ ...item, id: i }));
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoiceDetails_ForPayouts');
    throw errors;
  }
};
export const createPayout = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Payment/CreatePayout`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'CreatePayout');
    throw errors;
  }
};
export const getPayoutAttempts = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Payment/GetPayoutAttempts`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'GetPayoutAttempts');
    throw errors;
  }
};
