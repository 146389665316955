import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';
import axios from 'axios';
import inMemoryJWT from 'services/auth/inMemoryJWT';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchInvoicesAgainstCommodityDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchInvoicesAgainstCommodityDealMasterId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchInvoicesAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};

export const fetchListAgainstPersonId = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchListAgainstPersonId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchAllInvoicesAgainstInvokingPersonId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListAgainstPersonId(resData);
  } catch (err) {
    console.log('api-FetchAllInvoicesAgainstInvokingPersonId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoicesAgainstInvokingPersonId_ELOC');
    throw errors;
  }
};

export const fetchListAgainstPersonIdReport = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchListAgainstPersonId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchAllInvoicesAgainstInvokingPersonId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListAgainstPersonIdReport(resData);
  } catch (err) {
    console.log('api-FetchAllInvoicesAgainstInvokingPersonId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoicesAgainstInvokingPersonId_ELOC');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const fetchInvoiceDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchInvoiceDetails(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchInvoiceDetailsAgainstInvoiceMasterId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchInvoiceDetails(resData);
  } catch (err) {
    console.log('api-FetchInvoiceDetailsAgainstInvoiceMasterId-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchInvoiceDetailsAgainstInvoiceMasterId');
    throw errors;
  }
};

//Update Invoice Master Details
export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/UpdateInvoiceMasterDetails`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('UpdateInvoiceMasterDetails-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'UpdateInvoiceMasterDetails');
    throw errors;
  }
};

//Update Invoice PDF
export const updatePDF = async (params) => {
  try {
    const qs = reqTransform.updatePDF(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/UpdateInvoiceDocument?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updatePDF(resData);
  } catch (err) {
    console.log('UpdateInvoiceDocument-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'UpdateInvoiceDocument');
    throw errors;
  }
};

//Get PDF
export const fetchInvoicePDF = async (pdfLink) => {
  try {
    const url = `${pdfLink}`;
    const res = await utils.fetch(url).ready;
    return res;
  } catch (err) {
    console.log('api-pdf-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchInvoicePDF');
    throw errors;
  }
};

export const fetchConsigmentPDF = async (pdfLink) => {
  try {
    let token = inMemoryJWT.getToken() || '';
    const url = `${pdfLink}`;
    const res = await axios.get(pdfLink); //,{ headers: {"Authorization" : `Bearer ${token}`} }
    return res;
  } catch (err) {
    console.log('api-pdf-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchInvoicePDF');
    throw errors;
  }
};

//Record Payment on Invoice
export const createRecordPayment = async (params) => {
  try {
    let bodyParams = reqTransform.createRecordPayment(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/SaveUpdatePaymentAllocationAgainstInvoiceIds`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createRecordPayment(resData);
  } catch (err) {
    console.log('SaveUpdatePaymentAllocationAgainstInvoiceIds-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePaymentAllocationAgainstInvoiceIds');
    throw errors;
  }
};
export const createRecordPaymentFinance = async (params) => {
  try {
    let bodyParams = reqTransform.createRecordPayment(params);
    const url = `${baseUrl}/BioFuelPayments/api/TradeFinance/SaveUpdatePaymentAllocationAgainstInvoiceIds_Finance`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createRecordPayment(resData);
  } catch (err) {
    console.log('SaveUpdatePaymentAllocationAgainstInvoiceIds-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePaymentAllocationAgainstInvoiceIds_Finance');
    throw errors;
  }
};

//Get Cutsomer List
export const fetchCustomerList = async (params) => {
  try {
    const qs = reqTransform.fetchCustomerList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchCustomersByInvokingPersonId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCustomerList(resData);
  } catch (err) {
    console.log('api-FetchCustomersByInvokingPersonId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchCustomersByInvokingPersonId');
    throw errors;
  }
};

export const getPayers = async (params) => {
  try {
    const qs = reqTransform.fetchCustomerList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchPayersByInvokingPersonId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCustomerList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchPayersByInvokingPersonId');
    throw errors;
  }
};

export const createCRorDRNote = async (params) => {
  try {
    let qs = reqTransform.createCRorDRNote(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/CreateCNorDNForInvoice?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createCRorDRNote(resData);
  } catch (err) {
    console.log('CreateCNorDNForInvoice->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'CreateCNorDNForInvoice');
    throw errors;
  }
};

export const createESign = async (params) => {
  try {
    const qs = reqTransform.createESign(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/ESignature/ESignGateway?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createESign(resData);
  } catch (err) {
    console.log('api-ESignGateway-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ESignGateway');
    throw errors;
  }
};

export const fetchSellerBankList = async (params) => {
  try {
    let qsParams = reqTransform.fetchSellerBankList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/GetSellerBankAccountsAgainstInvoiceMasterId?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchSellerBankList(resData);
  } catch (err) {
    console.log('api-GetSellerBankAccountsAgainstInvoiceMasterId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetSellerBankAccountsAgainstInvoiceMasterId');
    throw errors;
  }
};

export const fetchDealBalancePosition = async (params = {}) => {
  try {
    const qs = reqTransform.fetchDealBalancePosition(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/GetDealPositions?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDealBalancePosition(resData);
  } catch (err) {
    console.log('api-GetDealPositions-fetchbalancePosition->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetDealPositions');
    throw errors;
  }
};

export const updateInvoiceActionStatus = async (params = {}) => {
  try {
    let qs = reqTransform.updateInvoiceActionStatus(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/UpdateInvoiceActionStatus?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updateInvoiceActionStatus(resData);
  } catch (err) {
    console.log('api-UpdateInvoiceActionStatus-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateInvoiceActionStatus');
    throw errors;
  }
};

export const getInvoiceVerificationStatusHistory = async (params = {}) => {
  try {
    let qs = reqTransform.getInvoiceVerificationStatusHistory(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchInvoiceVerificationStatusHistory?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.getInvoiceVerificationStatusHistory(resData);
  } catch (err) {
    console.log('api-getInvoiceVerificationStatusHistory->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'getInvoiceVerificationStatusHistory');
    throw errors;
  }
};

export const SaveUpdateInvoiceVerificationStatus_Bulk = async (params = {}) => {
  try {
    let bodyParams = reqTransform.SaveUpdateInvoiceVerificationStatus_Bulk(params);
    console.log('BODY PARAMS:', bodyParams);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/SaveUpdateInvoiceVerificationStatus_Bulk`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.saveUpdateInvoiceVerificationStatus(resData);
  } catch (err) {
    console.log('api-SaveUpdateInvoiceVerificationStatus_Bulk->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateInvoiceVerificationStatus_Bulk');
    throw errors;
  }
};

export const saveUpdateInvoiceVerificationStatus = async (params = {}) => {
  try {
    let bodyParams = reqTransform.saveUpdateInvoiceVerificationStatus(params);
    console.log('BODY PARAMS:', bodyParams);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/SaveUpdateInvoiceVerificationStatus`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.saveUpdateInvoiceVerificationStatus(resData);
  } catch (err) {
    console.log('api-saveUpdateInvoiceVerificationStatus->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateInvoiceActionStatus');
    throw errors;
  }
};

export const fetchEInvoiceQrCode = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/Zoho/FetchEInvoiceQrCode?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'fetchEInvoiceQrCode');
    throw errors;
  }
};

export const UpdateConsignmentDetails = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/UpdateConsignmentNoteDetails`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'UpdateConsignmentNoteDetails');
    throw errors;
  }
};

export const downloadInvoices = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/DownloadInvoices`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'downloadInvoices');
    throw errors;
  }
};
