import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import InfoIcon from 'common/Icons/InfoIcon';
import FinanceIcon from 'common/Icons/FieldInvoiceIcon';
import { INVOICE_NUMBER_ENUM } from 'constants/enums';
import './styles.scss';
import 'assets/scss/tooltip.scss';
import { getUrlWithEncryptedQueryParams } from 'lib/utils';

function invoiceField({ invoice_number, invoice_type, detailsPageLink, detailsPageLink1, invoice_number_enum, isFinancedInvoice, isRecord, isInvoiceType, rowInvoices, encodeBackUrl }) {
  const history = useHistory();

  return (
    <div className="bfc-table-list-components">
      <div className={!isRecord ? 'inovice-field d-flex justify-content-center align-items-center' : 'inovice-field'}>
        {rowInvoices ? (
          <>
          <br/>
          {
              _.map(rowInvoices, (id) => {
                return (
                  <>
                    <Link tabIndex='-1' to={getUrlWithEncryptedQueryParams(`/invoices/${id.Invoice_Master_Id}?back=${encodeBackUrl}&isinfo=true`)} className="commodity-code">
                    {id.Custom_Invoice_Number} <br />
                  </Link>
                  </>
                );
              })
            }
          </>
        ) : (
          <Link tabIndex='-1' to={detailsPageLink} className="commodity-code">
            {invoice_number ? invoice_number : ' '}
          </Link>
        )}
        <br />
        {(isFinancedInvoice || invoice_number_enum || invoice_type) && (
          <div className="tooltip-info-icon">
            <ul class={isRecord ? "fa-ul mb-0 p-0" : "fa-ul mb-0"}>
              <li class="tooltip_text">
                <span>
                  {isInvoiceType ? (
                    null
                  ) : (
                    invoice_type ? invoice_type : ' '
                  )}
                  {invoice_number_enum == INVOICE_NUMBER_ENUM.USER_DEFINED_INVOICE_NUMBER && (
                    <InfoIcon size={14} className="ml-0" />
                  )}
                  {isFinancedInvoice ? <FinanceIcon size={14} className="ml-5" /> : ''}
                </span>
                {invoice_number_enum == INVOICE_NUMBER_ENUM.USER_DEFINED_INVOICE_NUMBER && (
                  <span class="tooltip-hover">
                    <ul class="tooltip_list">
                      <li>
                        {
                          'Custom Invoice Number provided manually by the Seller. BiofuelCircle cannot guarantee unique numbering sequence'
                        }
                      </li>
                    </ul>
                  </span>
                )}
                {isFinancedInvoice ? (
                  <span class="tooltip-hover">
                    <ul class="tooltip_list">
                      <li>{'Seller has Financed this Invoice'}</li>
                    </ul>
                  </span>
                ) : (
                  ''
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

invoiceField.propTypes = {
  invoice_number: PropTypes.string.isRequired,
  invoice_type: PropTypes.string.isRequired,
};

export default invoiceField;
