import React from 'react';
import PropTypes from 'prop-types';
import utils from 'lib/utils';

import './styles.scss';
function AmountField({ expected_price, type, customStyle, invoice_type, isTrue, price_uom_code, isDeleted }) {
  let classname = 'price-field';
  let value = utils.formatQtyPricePerUOM(expected_price, price_uom_code);
  if (type == 'invoice') {
    classname = 'price-green-field';
    value = utils.formatQtyPricePerUOM(expected_price, price_uom_code);
    if (expected_price < 0) {
      classname = 'price-red-field';
      value = utils.formatNegativePricePerUOM(expected_price * -1, price_uom_code);
    }
    if (type == 'noInvoice') {
      classname = 'price-black-field';
      value = utils.formatQtyPricePerUOM(expected_price, price_uom_code);
    }
  }
  else if(type == 'bids'){
    classname = 'price-green-field';
    value =  '   + ' + utils.formatQtyPricePerUOM(expected_price, price_uom_code);
    if (expected_price < 0) {
      classname = 'price-red-field';
      value = '   - ' + utils.formatQtyPricePerUOM(expected_price * -1, price_uom_code);
    }
  }
  const deletedRecordStyle = {
    textDecoration: 'line-through',
    textDecorationThickness: '2px'
  }
  return (
    <div className="bfc-table-list-components" style={isDeleted ? deletedRecordStyle : {}}>
      <div className={`${classname} text-right`} style={customStyle}>
        {isTrue ? (
          <>
            {invoice_type === 'Commodity Invoice' ? value.includes('₹') ? `${value}` : `₹${value}`: ''}
          </>
        ) : (
          <>
            {value.includes('₹') ? value : `₹${value}`}
          </>
        )}
      </div>
    </div>
  );
}

AmountField.propTypes = {
  expected_price: PropTypes.number.isRequired,
};

export default AmountField;
