import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';
import { actions as uploadDocActions } from 'redux/uploadDoc';

const { baseUrl } = config;

export const upload = async (params, entity_type_enum, front_back_enum, entity_id, attachmentIdToPass) => {
  try {
    let bodyParams;
    if (window.location.href.includes('isFarmer')) {
      bodyParams = {
        ...params,
        entity_type_enum,
        front_back_enum,
        entity_id,
        attachmentIdToPass,
        user_type_enum: 2,
        document_module_enum: 1,
        company_affiliate_enum: 1,
      };
    } else {
      bodyParams = window?.location?.href.includes('farmer-onboarding')
        ? {
            file: params,
            entity_type_enum,
            front_back_enum,
            entity_id,
            attachmentIdToPass,
            user_type_enum: 2,
            document_module_enum: 1,
            company_affiliate_enum: 1,
          }
        : reqTransform.upload(params, entity_id);
    }

    const formData = new FormData();

    formData.append('entity_Id', bodyParams.entity_id);
    formData.append('entity_Type_Enum', entity_type_enum);
    formData.append('document_Module_Enum', bodyParams.document_module_enum);
    formData.append('company_Affiliate_Enum', bodyParams.company_affiliate_enum);
    formData.append('user_Type_Enum', bodyParams.user_type_enum);
    formData.append('front_Back_Enum', front_back_enum);
    formData.append(
      'attachment_File',
      window?.location?.href.includes('farmer-onboarding') ? bodyParams['file'] : bodyParams[0],
    );
    formData.append('attachment_Id', attachmentIdToPass);

    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/AttachDocument_Azure`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AttachDocument_Azure');
    throw errors;
  }
};

export const fetchList = async (params, dispatch) => {
  try {
    const qs = params?.isFacilitator ? utils.getQs(params) : reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    const res1 = _.get(resData, 'attachments') || [];
    const flag = _.get(resData, 'submit_for_approval') || false;
    const showCancelledCheque = _.get(resData, 'show_crossed_cancelled_cheque') || false;
    const showMSMEClassificationDocument = _.get(resData, 'show_msme_classification_document') || false;
    dispatch(uploadDocActions.updateApprovalFlag(flag));
    dispatch(uploadDocActions.updateCancelledChequeFlag(showCancelledCheque));
    dispatch(uploadDocActions.updateshowMSMEClassificationFlag(showMSMEClassificationDocument));
    return resTransform.fetchList(res1);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetAttachmentAgainstEntityId');
    throw errors;
  }
};

export const getAttachment = async (params, dispatch) => {
  try {
    const qs = reqTransform.fetchQualityLabList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    const res1 = _.get(resData, 'attachments') || [];
    const flag = _.get(resData, 'submit_for_approval') || false;
    const showCancelledCheque = _.get(resData, 'show_crossed_cancelled_cheque') || false;
    const showMSMEClassificationDocument = _.get(resData, 'show_msme_classification_document') || false;
    dispatch(uploadDocActions.updateApprovalFlag(flag));
    dispatch(uploadDocActions.updateCancelledChequeFlag(showCancelledCheque));
    dispatch(uploadDocActions.updateshowMSMEClassificationFlag(showMSMEClassificationDocument));
    return resTransform.fetchList(res1);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetAttachmentAgainstEntityId');
    throw errors;
  }
};

export const fetchDetails = async (id, dispatch) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({}, dispatch);
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-uploadDoc-fetchDetails->err---->', err);
    throw err;
  }
};

export const handleQualityLabUpload = async (
  params,
  entity_type_enum,
  front_back_enum,
  entity_id,
  attachmentIdToPass,
  document_module_enum,
  company_affiliate_enum,
) => {
  try {
    let bodyParams = reqTransform.handleQualityLabUpload(params, entity_id);

    const formData = new FormData();

    formData.append('entity_Id', bodyParams.entity_id);
    formData.append('entity_Type_Enum', entity_type_enum);
    formData.append('document_Module_Enum', document_module_enum);
    formData.append('company_Affiliate_Enum', company_affiliate_enum);
    formData.append('user_Type_Enum', bodyParams.user_type_enum);
    formData.append('front_Back_Enum', front_back_enum);
    formData.append('attachment_File', bodyParams[0]);
    formData.append('attachment_Id', attachmentIdToPass);

    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/AttachDocument_Azure`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AttachDocument_Azure');
    throw errors;
  }
};

export const fetchQualityLabList = async (qualityId) => {
  try {
    const qs = reqTransform.fetchQualityLabList(qualityId);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetPublicAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    const res1 = _.get(resData, 'attachments') || [];

    return resTransform.fetchQualityLabList(res1);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetPublicAttachmentAgainstEntityId');
    throw errors;
  }
};

export const fetchExtendedProfilePhotosList = async (qualityId) => {
  try {
    const qs = reqTransform.fetchExtendedProfilePhotosList(qualityId);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    const res1 = _.get(resData, 'attachments') || [];

    return resTransform.fetchExtendedProfilePhotosList(resData);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetPublicAttachmentAgainstEntityId');
    throw errors;
  }
};

export const fetchManualInvoiceDocumentList = async (qualityId) => {
  try {
    const qs = reqTransform.fetchManualInvoiceDocumentList(qualityId);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetPublicAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchManualInvoiceDocumentList(resData);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetPublicAttachmentAgainstEntityId');
    throw errors;
  }
};
