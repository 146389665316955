/* eslint-disable no-unused-vars */
import _ from 'lodash';
import qs from 'query-string';
import * as yup from 'yup';
import * as api from '../teams/api';
import * as fetch from 'services/fetch';

const BASE_URL = '/BioFuelRequirementOffers/api';
import utils from 'lib/utils';

export type DealScheduleType = {
  transport_delivery_schedule_id: number;
  commodity_deal_master_id: number;
  transport_deal_master_id: number;
  reference_transport_delivery_schedule_id: number;
  transport_periodicity_enum: string;
  delivery_schedule_start_date: Date;
  delivery_schedule_end_date: Date;
  system_delivery_qty: number;
  buyer_proposed_delivery_qty: number;
  seller_proposed_delivery_qty: number;
  final_delivery_qty: number;
  buyer_accept_reject_flag: boolean;
  seller_accept_reject_flag: boolean;
  is_final: boolean;
  is_transport_allocated: boolean;
  number_of_vehicles_required: number;
  number_of_vehicles_allocated: number;
  open_number_of_vehicles: number;
  is_delivered: boolean;
  status_enum: boolean;
  created_datetime: Date;
  created_by: number;
  modified_by: number;
  modified_datetime: Date;
  lock_id: number;
};

const DealDataSchema = yup
  .object()
  .shape({
    commodity_deal_master_id: yup.number().required().positive().integer(),
    buyer_person_id: yup.number().required().positive().integer(),
    seller_person_id: yup.number().required().positive().integer(),
    commodity_req_offer_id: yup.number().required().positive().integer(),
    buy_sell_initial_enum: yup.number().required().positive().integer(),
    buy_sell_initial_enum_code: yup.string().defined(),
    deal_initiated_by_enum: yup.number().required().positive().integer(),
    deal_initiated_by_enum_code: yup.string().defined(),
    buyer_company_id: yup.number().defined(),
    seller_company_id: yup.number().required().positive().integer().defined(),
    deal_date: yup.date().defined(),
    deal_status_enum: yup.number().defined(),
    deal_status_enum_code: yup.string().defined(),
    commodity_id: yup.number().required().positive().integer().defined(),
    commodity_code: yup.string().defined(),
    commodity_form_id: yup.number().required().positive().integer().defined(),
    commodity_form_code: yup.string().defined(),
    qty: yup.number().required().positive().integer().defined(),
    qty_uom_id: yup.number().required().positive().integer().defined(),
    qty_uom_code: yup.string().defined(),
    periodicity_enum: yup.number().required().positive().integer().defined(),
    periodicity_enum_code: yup.string().defined(),
    from_date: yup.date().defined(),
    to_date: yup.date().defined(),
    open_qty: yup.number().required().positive().integer().defined(),
    min_qty: yup.number().required().positive().integer().defined(),
    total_qty: yup.number().required().positive().integer().defined(),
    trade_price: yup.number().required().positive().integer().defined(),
    price_currency_id: yup.number().required().positive().integer().defined(),
    price_currency_code: yup.string().defined(),
    price_uom_id: yup.number().required().positive().integer().defined(),
    price_uom_code: yup.string().defined(),
    payment_term_id: yup.number().required().positive().integer().defined(),
    payment_term_code: yup.string().defined(),
    transport_deal_master_id: yup.number().defined(),
    company_affiliate_enum: yup.number().required().integer().defined(),
    user_type_enum: yup.number().required().positive().integer().defined(),
    company_affiliate_enum_code: yup.string().defined(),
    user_type_enum_code: yup.string().defined(),
    address_id: yup.number().required().positive().integer().defined(),
  })
  .defined();

const DealLabelSchema = yup
  .object()
  .shape({
    buy_sell_initial_enum_code: yup.string().defined(),
  })
  .defined();

const DealMixedSchema = DealDataSchema.concat(DealLabelSchema);
const DealMixedArraySchema = yup.array().of(DealMixedSchema);

export type DealDataType = yup.InferType<typeof DealDataSchema>;
export type DealLabelType = yup.InferType<typeof DealLabelSchema>;
export type DealMixedType = yup.InferType<typeof DealMixedSchema>;
export type BalanceQuantityType = {
  table_id: number;
  requirement_qty: number;
  purchased_qty: number | null;
  balance_qty: number | null;
  period_name: string;
};

// const FetchAllCommodityDealsAgainstEntityId = async (page: number, pageSize: number) => {
//   // /api/Deal/FetchAllCommodityDealsAgainstEntityId
//   const urlParams = {
//     entity_id: globalThis?.user?.userid,
//     company_affiliate_enum: 1, // @TODO: hardcoded
//     user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
//     buy_sell_initial_enum: 1, // @TODO: hardcoded
//     offset: (page - 1) * pageSize,
//     page_size: pageSize,
//   };

//   const acl = utils.getACL(_.get(window, 'user') || {});
//   if (!acl.isCompany) {
//     // urlParams['entity_id'] = urlParams['person_id'];
//     urlParams['entity_id'] = _.get(window, 'user.userid') || 0;
//     urlParams['user_type_enum'] = 2;
//   } else {
//     urlParams['entity_id'] = _.get(window, 'user.profile.person.company_id') || 0;
//     urlParams['user_type_enum'] = 1;
//   }

//   const url = `${BASE_URL}/Deal/FetchAllCommodityDealsAgainstEntityId?${qs.stringify(urlParams)}`;

//   const listData = await fetch.fetchList<DealMixedType>(url);

//   yup.array().of(DealLabelSchema).validateSync(listData.data);

//   return listData;
// };

const FetchAllCommodityDealsAgainstEntityId = async (filters, page: number, pageSize: number, resetDealDate) => {
  // /api/Deal/FetchAllCommodityDealsAgainstEntityId

  let urlParams = {
    filters: {
      ...filters,
    },
    entity_id: globalThis?.user?.userid,
    company_affiliate_enum: 1, // @TODO: hardcoded
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    buy_sell_initial_enum: 1, // @TODO: hardcoded
    offset: !_.isEmpty(filters) && !resetDealDate ? 0 : (page - 1) * pageSize,
    page_size: pageSize,
  };

  const acl = utils.getACL(_.get(window, 'user') || {});
  if (!acl.isCompany) {
    // urlParams['entity_id'] = urlParams['person_id'];
    urlParams['entity_id'] = _.get(window, 'user.userid') || 0;
    urlParams['user_type_enum'] = 2;
  } else {
    urlParams['entity_id'] = _.get(window, 'user.profile.person.company_id') || 0;
    urlParams['user_type_enum'] = 1;
  }

  // const url = `${BASE_URL}/Deal/FetchAllCommodityDealsAgainstEntityIdWithFilter`;

  // const listData = await fetch.fetchList1<DealMixedType>(url, urlParams);

  // yup.array().of(DealLabelSchema).validateSync(listData.data);

  const listData = await api.fetchListPost(urlParams);

  yup.array().of(DealLabelSchema).validateSync(listData.data);
  return listData;
};

const fetchAllDealsCt = async (params) => {
  const dealsData = await api.fetchDealList(params);
  return dealsData;
};

const FetchCommodityDealDetailsAgainstDealMasterId = async (commodityDealMasterId) => {
  // /api/Deal/FetchCommodityDealDetailsAgainstDealMasterId
  const urlParams = {
    commodity_deal_master_id: commodityDealMasterId,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Deal/FetchCommodityDealDetailsAgainstDealMasterId_ELOC?${qs}`;

  const details = await fetch.fetchOne<DealMixedType>(url);

  return details;
};

const FetchScheduleAgainstCommodityDealMasterId = async (
  commodityDealMasterId: string,
  transportPeriodicityEnum: number,
  fromDate,
  toDate,
  page: number,
  pageSize: number,
) => {
  const finalPayload = {
    commodity_deal_master_id: parseInt(commodityDealMasterId),
    transport_periodicity_enum: transportPeriodicityEnum,
    from_date: fromDate,
    to_date: toDate,
    offset: (page - 1) * pageSize,
    page_size: pageSize,
  };

  const url = `${BASE_URL}/Deal/FetchScheduleAgainstCommodityDealMasterId`;

  const details = await fetch.create<{}>(url, finalPayload);

  return details;
};

const UpdateScheduleAgainstCommodityDealMasterId = async (commodityDealMasterId, payload) => {
  const urlParams = {
    commodity_deal_master_id: commodityDealMasterId,
  };

  const finalPayload = {
    ...payload,
    // person_id: globalThis?.user?.userid,
    // user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    // company_affiliate_enum: 1, // @TODO: hardcoded,
    // quality_measured_at_enum: 1, // @TODO: hardcoded,
    // vehicle_type_id: 1, // @TODO: hardcoded,
  };

  const qs = utils.getQs(finalPayload);
  const url = `${BASE_URL}/Deal/UpdateScheduleAgainstCommodityDealMasterId_ELOC?${qs}`;

  const details = await fetch.update<DealScheduleType>(url, finalPayload);

  return details;
};

// /api/Deal/AcceptRejectScheduleAgainstCommodityDealMasterId

const AcceptRejectScheduleAgainstCommodityDealMasterId = async (
  commodityDealMasterId,
  acceptRejectFlag,
  transportPeriodicityEnum,
) => {
  const urlParams = {
    commodity_deal_master_id: commodityDealMasterId,
    person_id: globalThis?.user?.userid,
    accept_reject_flag: acceptRejectFlag,
    transport_periodicity_enum: transportPeriodicityEnum,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Deal/AcceptRejectScheduleAgainstCommodityDealMasterId?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const FetchBalanceQuantity = async (commodityReqOfferId) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    invoking_person_id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Deal/FetchBalanceQuantity?${qs}`;

  const bqDetails = await fetch.fetchList<BalanceQuantityType>(url);

  return bqDetails;
};

const FetchIndentBalanceQuantity = async (commodityReqOfferId) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    invoking_person_id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Deal/FetchIndentBalanceQuantity?${qs}`;

  const bqDetails = await fetch.fetchList<BalanceQuantityType>(url);

  return bqDetails;
};

const FetchCommodityDealsAgainstCommodityReqOfferId = async (commodityReqOfferId, filters, page, pageSize) => {
  // const urlParams = {
  //   commodity_req_offer_id: id,
  //   offset: 0,
  //   page_size: pageSize,
  // };

  const finalPayload = {
    filters: {
      ...filters,
      // commodity_req_offer_id: commodityReqOfferId,
      // preferred_blocked_enum: 1,
    },
    entity_id: parseInt(commodityReqOfferId),
    offset: (page - 1) * pageSize,
    page_size: pageSize,
    invoking_person_id: _.get(window, 'user.userid'),
  };

  const url = `${BASE_URL}/Deal/FetchCommodityDealsAgainstCommodityReqOfferId_ELOC`;

  const listData = await fetch.create(url, finalPayload);

  return listData;
};

const RejectDeal = async (commodityDealMasterId, rejectionReason) => {
  const urlParams = {
    commodity_deal_master_id: commodityDealMasterId,
    rejected_by_id: globalThis?.user?.userid,
    rejection_reason: rejectionReason,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Deal/RejectDeal?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const FetchDeliveryScheduleViewAgainstDealId = async (commodityDealMasterId) => {
  // /api/Deal/FetchAllCommodityDealsAgainstEntityId
  const urlParams = {
    commodity_deal_master_id: commodityDealMasterId,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Deal/FetchDeliveryScheduleViewAgainstDealId?${qs}`;

  const listData = await fetch.fetchList<{}>(url);

  return listData;
};
// Omkar 4547 (Added Apis)
const FetchAllFeedbacks = async () => {
  const data = {
    filters: {},
    page_size: 10,
    offset: 0,
  };
  const url = `/BioFuelCommonUtilities/api/Feedback/FetchAllFeedbacks`;
  const feedbackData = await fetch.create(url, data);
  // return resTransform.fetchDerivedAutoBidOffersList(feedbackData.data);
  return feedbackData;
};

const FetchFeedbackForUser = async () => {
  const data = {
    filters: {},
  };
  const url = `/BioFuelCommonUtilities/api/Feedback/FetchFeedbackForUser`;
  const feedbackData = await fetch.create(url, data);
  // return resTransform.fetchDerivedAutoBidOffersList(feedbackData.data);
  return feedbackData;
};

const SubmitFeedback = async (feedbackId, isDelayed) => {
  const data = {
    feedback_Id: feedbackId,
    is_Delayed: isDelayed,
  };
  const url = `/BioFuelCommonUtilities/api/Feedback/SubmitFeedback`;
  const feedbackData = await fetch.update(url, data);
  // return resTransform.fetchDerivedAutoBidOffersList(feedbackData.data);
  return feedbackData;
};

const FetchHelperTextForDistance = async (commodity_type_id, commodity_form_id, commodity_id) => {
  const data = {
    filters: {
      commodity_type_id: commodity_type_id.toString(),
      commodity_form_id: commodity_form_id.toString(),
      commodity_id: commodity_id.toString(),
    },
    offset: 0,
    page_Size: 10,
  };
  const url = `/BioFuelCommonUtilities/api/Geofence/FetchGeofencingData`;
  const helperText = await fetch.create(url, data);
  // return resTransform.fetchDerivedAutoBidOffersList(helperText.data);
  return helperText;
};

export default {
  FetchAllCommodityDealsAgainstEntityId,
  FetchCommodityDealDetailsAgainstDealMasterId,
  FetchScheduleAgainstCommodityDealMasterId,
  UpdateScheduleAgainstCommodityDealMasterId,
  FetchBalanceQuantity,
  FetchCommodityDealsAgainstCommodityReqOfferId,
  AcceptRejectScheduleAgainstCommodityDealMasterId,
  RejectDeal,
  FetchDeliveryScheduleViewAgainstDealId,
  fetchAllDealsCt,
  FetchIndentBalanceQuantity,
  FetchAllFeedbacks,
  FetchFeedbackForUser,
  SubmitFeedback,
  FetchHelperTextForDistance,
};

export { DealDataSchema, DealLabelSchema, DealMixedSchema, DealMixedArraySchema };
