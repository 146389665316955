import React, { ElementType, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import Avatar from '@material-ui/core/Avatar';
import InfiniteScroll from 'react-infinite-scroll-component';
import { colord as colorFormat, extend } from 'colord';
import getColor from '../colors';
import namesPlugin from 'colord/plugins/names';
import _ from 'lodash';
extend([namesPlugin]);

type MUITimelinePropsTypes = {
  data: {}[];
  align?: 'left' | 'right' | 'alternate';
  themeColor?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary';
  timelineDotVariant: 'outlined' | 'default';
  Icon?: ElementType | string;
  isAvatar?: boolean;
  generateChildContent?: (value: any) => ReactNode;
  fetchMoreData: () => void;
  scrollHeight?: string;
  apiDateParam?: string;
  apiAvatarImageParam?: string;
  apiUsernameParam?: string;
  apiHeadingParam?: string;
};

const MUITimeline = ({
  data,
  align = 'left',
  themeColor = 'muiPrimary',
  timelineDotVariant = 'outlined',
  Icon,
  isAvatar = true,
  generateChildContent,
  fetchMoreData,
  scrollHeight = 'auto',
  apiDateParam = 'date_time',
  apiAvatarImageParam = 'image_url',
  apiUsernameParam = 'person_name',
  apiHeadingParam = 'person_company_affiliate_name',
}: MUITimelinePropsTypes) => {
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config');
  useEffect(() => {
    if (align !== 'alternate') {
      document
        .querySelectorAll('.MuiTimelineItem-alignLeft')
        ?.forEach((item) => item?.classList.remove('MuiTimelineItem-missingOppositeContent'));
      document
        .querySelectorAll('.MuiTimelineItem-alignRight')
        ?.forEach((item) => item?.classList.remove('MuiTimelineItem-missingOppositeContent'));
    }
  }, [align]);

  const bgColor = getColor(config?.theme_color || themeColor);
  const textColor = timelineDotVariant === 'default' ? '#fff' : bgColor;

  const card: React.CSSProperties = {
    backgroundColor: colorFormat(bgColor).alpha(0.14).toHex(),
    color: bgColor,
    fontWeight: 'bold',
    borderRadius: '8px',
    letterSpacing: '1px',
  };

  const dateTimeStyle: React.CSSProperties = {
    color: colorFormat(bgColor).alpha(0.85).toHex(),
    position: 'absolute',
    top: '3px',
    right: '12px',
    fontSize: '9px',
  };

  const getAvatar = (item, margin = '0 10px 0 0') => (
    <Tooltip zIndex={1500} title={item?.[apiUsernameParam] || ''}>
      <Avatar
        src={item?.[apiAvatarImageParam]}
        style={{
          cursor: 'pointer',
          height: 30,
          width: 30,
          color: bgColor,
          backgroundColor: colorFormat(bgColor).alpha(0.24).toHex(),
          margin,
        }}
      />
    </Tooltip>
  );

  return (
    <InfiniteScroll
      height={scrollHeight}
      style={{ overflowX: 'hidden' }}
      dataLength={data?.length}
      next={fetchMoreData}
      hasMore={data?.[0]?.['hasMore'] || data?.length < data?.[0]?.['total_count']}
      loader={undefined}
    >
      <Timeline align={align}>
        {data?.length
          ? data?.map((item, i) => (
              <TimelineItem key={`${item?.[apiHeadingParam]}-${i}`}>
                <TimelineSeparator>
                  <TimelineDot
                    variant={timelineDotVariant}
                    style={{ borderColor: bgColor, backgroundColor: timelineDotVariant === 'default' ? bgColor : '' }}
                  >
                    {Icon ? (
                      typeof Icon === 'string' ? (
                        Icon
                      ) : (
                        <Icon style={{ height: 24, width: 24, fill: textColor, color: textColor }} />
                      )
                    ) : (
                      ''
                    )}
                  </TimelineDot>
                  {data?.length > 1 && i < data?.length - 1 ? (
                    <TimelineConnector style={{ backgroundColor: bgColor }} />
                  ) : (
                    ''
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <div
                    style={{ ...card, padding: item?.[apiDateParam] ? '18px 12px 12px 12px' : '12px' }}
                    className="position-relative"
                  >
                    {item?.[apiDateParam] ? (
                      <span style={dateTimeStyle}>{dayjs(item?.[apiDateParam]).format('DD-MMM-YYYY hh:mm a')}</span>
                    ) : (
                      ''
                    )}
                    <div
                      className={`d-flex justify-content-${
                        align === 'left'
                          ? 'start'
                          : align === 'right'
                          ? 'end'
                          : align === 'alternate'
                          ? (i + 1) % 2 !== 0
                            ? 'start'
                            : 'end'
                          : ''
                      } align-items-center`}
                    >
                      {isAvatar && align === 'left' ? getAvatar(item) : ''}
                      {isAvatar && align === 'alternate' && (i + 1) % 2 !== 0 ? getAvatar(item) : ''}
                      <div style={{ overflowWrap: 'anywhere' }}>
                        <span style={{ textTransform: 'uppercase' }}>{item?.[apiHeadingParam]}</span>
                        <Typography
                          component={'div'}
                          style={{ color: colorFormat(bgColor).alpha(0.85).toHex(), letterSpacing: '1px' }}
                        >
                          {generateChildContent && generateChildContent(item)}
                        </Typography>
                      </div>
                      {isAvatar && align === 'right' ? getAvatar(item, '0 0 0 10px') : ''}
                      {isAvatar && align === 'alternate' && (i + 1) % 2 === 0 ? getAvatar(item, '0 0 0 10px') : ''}
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))
          : ''}
      </Timeline>
    </InfiniteScroll>
  );
};
export default MUITimeline;
