import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Skeleton } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';

import OverviewHeader from 'common/OverviewHeader';
import AntPagination from 'common/AntTablePagination';
import { actions as earlyPaymentsActions } from 'redux/earlyPayments';
import useQuery from 'lib/hooks/useQuery';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FilterUtils from 'lib/filterUtils';

import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import { actions as generateEquipmentRentalCSVReportActions } from 'redux/generateReportCommon';
import OverviewNav from 'common/OverviewNav';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import AmountField from 'common/TableComponents/amountField';
// import LogoLoader from 'common/LogoLoader';
import { LinkOutlined } from '@material-ui/icons';

const EarlyPayment = () => {
  const dispatch = useDispatch();
  const { page = 1 } = useQuery();
  const history = useHistory();
  const csvLink = useRef();
  const tableRef = useRef();

  let {
    commodity_deal_master_id,
    delivery_allotment_status_enum,
    transport_delivery_schedule_alloted_id,
    delivery_status_enum,
    seller_company_code,
    buyer_company_code,
    custom_invoice_number,
    invoice_status_enum,
    deal_price,
    early_payment_discount,
    pickup_date,
    invoice_quantity,
    due_date,
    linked_invoicestatusenum,
    linked_custominvoicenumber
  } = useQuery();
  const rentalState = useSelector((state) => state.earlyPayments);
  const equipmentData = Object.values(rentalState.entities);
  const busy = _.get(rentalState, 'busy') || false;

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'delivery_allotment_status_enum_code':
        delete params['delivery_allotment_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('delivery_allotment_status_enum'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'delivery_allotment_status_enum_code':
        params = {
          ...params,
          delivery_allotment_status_enum: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const back = utils.encodeBackUrl();

  const TRACKING_TABLE_HEADERS = [
    {
      title: 'Deal',
      dataIndex: 'commodity_deal_master_id',
      key: 'equipment_master_id',
      width: '100px',
      ellipsis: true,
      align: 'center',
      fixed: true,
      sorter: (a, b) => a.commodity_deal_master_id - b.commodity_deal_master_id,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-center">
          <Link to={getUrlWithEncryptedQueryParams(`/control-tower/deals/${row.commodity_deal_master_id}/overview?back=${back}`)}>
            {row.commodity_deal_master_id}
          </Link>
          {
            row.commodity_deal_master_id && row.to_deal_ids !== null ?
              <div style={{ paddingLeft: '5px' }}>
                <LinkOutlined onClick={() => handleShowLinkedDeals(row)} rotate={45} style={{ fontSize: 'var(--fs-base__one)', color: 'var(--unnamed-color-1b62ab)' }} />
              </div> : ''
          }
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'commodity_deal_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_deal_master_id}
          name={'Id'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'commodity_deal_master_id'),
      filteredValue: commodity_deal_master_id !== undefined ? commodity_deal_master_id : null,
    },
    {
      title: 'Delivery No.',
      dataIndex: 'transport_delivery_schedule_alloted_id',
      key: 'transport_delivery_schedule_alloted_id',
      width: '130px',
      align: 'center',
      fixed: true,
      render: (text, row) => (
        <div className="d-flex justify-content-between align-items-center">
          <Link to={getUrlWithEncryptedQueryParams(`/deliveries/${row.transport_delivery_schedule_alloted_id}/overview?back=${back}`)}>
            {row.transport_delivery_schedule_alloted_id}
          </Link>
          {
            row.transport_delivery_schedule_alloted_id && row.to_delivery_ids !== null ?
              <span style={{ paddingLeft: '5px' }}>
                <LinkOutlined onClick={() => handleShowLinkedDeliveries(row)} rotate={45} style={{ fontSize: 'var(--fs-base__one)', color: 'var(--unnamed-color-1b62ab)' }} />
              </span> : ''
          }
        </div>
      ),
      sorter: (a, b) => a.transport_delivery_schedule_alloted_id - b.transport_delivery_schedule_alloted_id,
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'transport_delivery_schedule_alloted_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={transport_delivery_schedule_alloted_id}
          name={'Delivery No'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'transport_delivery_schedule_alloted_id'),
      filteredValue:
        transport_delivery_schedule_alloted_id !== undefined ? transport_delivery_schedule_alloted_id : null,
    },
    {
      title: 'Delivery Status',
      dataIndex: 'delivery_status_enum_code',
      key: 'delivery_status_enum_code',
      width: '170px',
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Rejected' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Unassigned' && (
            <Tag color={'geekblue'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {(text == 'Confirmed' || text == 'CloseOut') && (
            <Tag color={'blue'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {(text === 'Closed' || text === 'Close Out') && (
            <Tag color={'volcano'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Scheduled' && (
            <Tag color={'magenta'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Delivered' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {(text == 'Trip Started' ||
            text == 'In Transit For Load' ||
            text == 'At Loading Location' ||
            text == 'Goods Loaded Acknowledgement' ||
            text == 'In Transit For Delivery' ||
            text == 'At Delivery Location' ||
            text == 'Goods Delivered Acknowledgement' ||
            text == 'Trip Ended') && (
              <Tag color={'purple'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
        </span>
      ),
      filterDropdown: (props) => (
        <MultiSelectFilter
          dataIndex={'delivery_allotment_status_enum_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={delivery_allotment_status_enum}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'delivery_allotment_status_enum'),
      defaultFilteredValue: delivery_allotment_status_enum !== undefined ? delivery_allotment_status_enum : null,
    },
    {
      title: 'Date',
      dataIndex: 'pickup_date',
      key: 'equipment_master_id',
      width: '150px',
      ellipsis: true,
      align: 'center',
      sorter: (a, b) => new Date(a.pickup_date) - new Date(b.pickup_date),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-center">
          {row.pickup_date ? dayjs(row.pickup_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'pickup_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={pickup_date}
          name={'Date'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'pickup_date'),
      filteredValue: pickup_date !== undefined ? pickup_date : null,
    },
    {
      title: 'Seller',
      dataIndex: 'seller_company_code',
      key: 'seller_company_code',
      width: '150px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) => a.seller_company_code && a.seller_company_code.localeCompare(b.seller_company_code && b.seller_company_code),
      render: (text, row) => (
        <div className="bfc-table-list-components text-left d-flex justify-content-start">{row.seller_company_code}</div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'seller_company_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={seller_company_code}
          name={'Seller'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'seller_company_code'),
      filteredValue: seller_company_code !== undefined ? seller_company_code : null,
    },
    {
      title: 'Buyer',
      dataIndex: 'buyer_company_code',
      key: 'buyer_company_code',
      width: '150px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) => a.buyer_company_code && a.buyer_company_code.localeCompare(b.buyer_company_code && b.buyer_company_code),
      render: (text, row) => (
        <div className="bfc-table-list-components text-left d-flex justify-content-start">{row.buyer_company_code}</div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'buyer_company_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={buyer_company_code}
          name={'Buyer'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'buyer_company_code'),
      filteredValue: buyer_company_code !== undefined ? buyer_company_code : null,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'custom_invoice_number',
      key: 'custom_invoice_number',
      width: '150px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-left d-flex justify-content-center">
          <Link to={getUrlWithEncryptedQueryParams(`/invoices/${row['invoice_master_id']}?back=${back}`)}>
            {row.custom_invoice_number}
          </Link>
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'custom_invoice_number'}
          codeBaisedFilter={true}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={custom_invoice_number}
          name={'Address'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'custom_invoice_number'),
      filteredValue: custom_invoice_number !== undefined ? custom_invoice_number : null,
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      width: '150px',
      ellipsis: true,
      align: 'center',
      sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-center">
          {row.due_date ? dayjs(row.due_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'due_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={due_date}
          name={'Date'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'due_date'),
      filteredValue: due_date !== undefined ? due_date : null,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoice_amount',
      key: 'invoice_amount',
      width: '120px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <AmountField expected_price={row.invoice_amount} type={'invoice'} />,
    },
    {
      title: 'Qty',
      dataIndex: 'invoice_quantity',
      key: 'invoice_quantity',
      width: '100px',
      ellipsis: true,
      align: 'center',
      sorter: (a, b) => a.invoice_quantity - b.invoice_quantity,
      render: (text, row) => (
        <div className="bfc-table-list-components text-left d-flex justify-content-center">
          {row.invoice_quantity} {row?.qty_uom_code}
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'invoice_quantity'}
          codeBaisedFilter={true}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={invoice_quantity}
          name={'Invoice Quantity'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'invoice_quantity'),
      filteredValue: invoice_quantity !== undefined ? invoice_quantity : null,
    },
    {
      title: 'Status',
      dataIndex: 'invoice_status_enum_code',
      width: '100px',
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Open' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Initiated' && (
            <Tag color={'blue'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Paid' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Over Due' && (
            <Tag color={'purple'} key={text}>
              <div className="bfc-table-list-components text-center">DUE</div>
            </Tag>
          )}
          {text == 'Failed' && (
            <Tag color={'volcano'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Partially Paid' && (
            <Tag color={'orange'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Draft' && (
            <Tag color={'lime'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Sent' && (
            <Tag color={'geekblue'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Cancelled' && (
            <Tag color={'gold'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Collected' && (
            <Tag color={'cyan'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Void' && (
            <Tag color={'cyan'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'invoice_status_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={invoice_status_enum}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'invoice_status_enum'),
      defaultFilteredValue: invoice_status_enum !== undefined ? invoice_status_enum : null,
    },
    {
      title: 'Deal Price',
      dataIndex: 'deal_price',
      key: 'deal_price',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <AmountField expected_price={row.deal_price} type={'invoice'} price_uom_code={row.price_uom_code} />,
    },
    {
      title: 'Discount',
      dataIndex: 'early_payment_discount',
      key: 'early_payment_discount',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <AmountField expected_price={row.early_payment_discount * -1} type={'invoice'} price_uom_code={row.price_uom_code} />,
    },
    {
      title: 'Interest',
      dataIndex: 'cost_of_borrowing',
      key: 'cost_of_borrowing',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.cost_of_borrowing_rate} ({row.cost_of_borrowing}%)</div>,
    },
    {
      title: 'Margin',
      dataIndex: 'equipment_address',
      key: 'equipment_address',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.margin_rate} ({row.margin}%)</div>,
    },
    {
      title: 'Adjustment',
      dataIndex: 'equipment_address',
      key: 'equipment_address',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.adjustment_rate} ({row.adjustment_factor}%)</div>,
    },
    {
      title: 'Linked Invoice',
      dataIndex: 'linked_invoice',
      key: 'linked_invoice',
      width: '150px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.linked_custominvoicenumber?.split(',')?.map(item => <Link className='d-block' to={getUrlWithEncryptedQueryParams(`/invoices/${row['linked_invoicemasterid']}`)}>{item}</Link>)}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'linked_custominvoicenumber'}
          codeBaisedFilter={true}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={linked_custominvoicenumber}
          name={'Linked Invoice Number'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'linked_custominvoicenumber'),
      filteredValue: linked_custominvoicenumber !== undefined ? linked_custominvoicenumber : null,
    },
    {
      title: 'Linked Status',
      dataIndex: 'linked_invoicestatusenum',
      key: 'linked_invoicestatusenum',
      width: '130px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Open' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Initiated' && (
            <Tag color={'blue'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Paid' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Over Due' && (
            <Tag color={'purple'} key={text}>
              <div className="bfc-table-list-components text-center">DUE</div>
            </Tag>
          )}
          {text == 'Failed' && (
            <Tag color={'volcano'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Partially Paid' && (
            <Tag color={'orange'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Draft' && (
            <Tag color={'lime'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Sent' && (
            <Tag color={'geekblue'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Cancelled' && (
            <Tag color={'gold'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Collected' && (
            <Tag color={'cyan'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Void' && (
            <Tag color={'cyan'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Over Paid' && (
            <Tag color={'olive'} key={text}>
              <div className="bfc-table-list-components text-center">{row.linked_invoicestatusenum.toUpperCase()}</div>
            </Tag>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'linked_invoicestatusenum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={linked_invoicestatusenum}
          name={'Linked Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'linked_invoicestatusenum'),
      defaultFilteredValue: linked_invoicestatusenum !== undefined ? linked_invoicestatusenum : null,
    },
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };
  const tableData = equipmentData;
  const totalRecords = tableData?.[0]?.total_count;
  const reportData = busy ? [] : rentalState?.reportData;

  const getExportReport = async () => {
    const filterParams = {
      filters: {
        ...filters,
      },
      is_csv_report: true,
      page_size: 10000,
      offset: 0,
    };

    const success = await dispatch(earlyPaymentsActions.fetchEarlyPaymentRecords(filterParams));
    if (success) {
      csvLink.current.link.click();
    }
  };

  let links = [
    {
      to: `/control-tower/early-payment?page=1`,
      title: 'Tracking',
    },
    // {
    //   to: `/control-tower/early-payment/discount-rate?page=1`,
    //   title: 'Discount Rate',
    // },
    // {
    //   to: `/control-tower/early-payment/margin?page=1`,
    //   title: 'Margin',
    // },
    {
      to: `/control-tower/adjustment-factor?page=1`,
      title: 'Adj. Factor',
    },
    {
      to: `/control-tower/minimum-discount?page=1`,
      title: 'Min. Discount',
    },
  ];

  const handleShowLinkedDeals = (row, e) => {
    const commodityMasterId = row.commodity_deal_master_id.toString()
    const toDealId = row.to_deal_ids ? row.to_deal_ids : ''
    const dealIdsData = commodityMasterId.concat(',', toDealId);
    let params = {
      commodity_deal_master_id: dealIdsData,
    }
    // setFilters(params);
    window.open(`/controltower/invoices?page=1&${utils.getQs(params)}`, '_blank');
  }
  const handleShowLinkedDeliveries = (row) => {
    const deliveryId = row.transport_delivery_schedule_alloted_id.toString()
    const toDeliveryId = row.to_delivery_ids ? row.to_delivery_ids : ''
    const deliveryData = deliveryId.concat(',', toDeliveryId);
    let params = {
      delivery_id: deliveryData,
    }
    // setFilters(params);
    window.open(`/controltower/invoices?page=1&${utils.getQs(params)}`, '_blank');
  }

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      commodity_deal_master_id,
      transport_delivery_schedule_alloted_id,
      delivery_allotment_status_enum,
      seller_company_code,
      buyer_company_code,
      custom_invoice_number,
      invoice_status_enum,
      deal_price,
      early_payment_discount,
      pickup_date,
      invoice_quantity,
      due_date,
      linked_invoicestatusenum,
      linked_custominvoicenumber
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
    };
    const params = {
      filters: filterParams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(earlyPaymentsActions.fetchEarlyPaymentRecords(params));
  }, [
    commodity_deal_master_id,
    transport_delivery_schedule_alloted_id,
    delivery_status_enum,
    seller_company_code,
    buyer_company_code,
    custom_invoice_number,
    invoice_status_enum,
    deal_price,
    early_payment_discount,
    rowsPerPage,
    page, delivery_allotment_status_enum,
    pickup_date,
    invoice_quantity,
    due_date,
    linked_invoicestatusenum,
    linked_custominvoicenumber
  ]);

  return (
    <dev>
      <OverviewHeader heading={`Early Payment`} />
      <OverviewNav links={links} />
      {/* {busy && (
        <div className="commodity-wrapper">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      )} */}
      <div className="delivery-table bfc-body">
        <div>
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? TRACKING_TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : TRACKING_TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 510 }}
              className="ant-table"
              size="small"
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              clearFilters={clearFilters}
              rowsPerPage={rowsPerPage}
              // reportData={Object.values(reportData)}
              // getExportReport={getExportReport}
              // csvLink={csvLink}
              // type={'Tracking'}
              isDownloadExtract={true}
              setScrollToFirstRow={setScrollToFirstRow}
            />
          </div>
        </div>
      </div>
    </dev>
  );
};
export default EarlyPayment;
