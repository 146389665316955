import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import MUITextField from '../TextField';
import DatePickerWrapper from './DatePickerWrapper';
import format from 'date-fns/format';
import endOfDay from 'date-fns/endOfDay';
import getTime from 'date-fns/getTime';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';

type MUIDatePickerPropTypes = {
  type?: 'date' | 'range' | 'time' | 'date-time';
  pickerType?: 'date' | 'month' | 'year' | 'quarter';
  label?: string;
  monthsShown?: number;
  timeIntervals?: number;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  popperPlacement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end';
  form?: any;
  field?: any;
  value?: Date | string | { start: Date | string; end: Date | string };
  onChange?: (value: Date | string | [Date | string, Date | string], dateString?: string) => void;
  minDate?: Date | string;
  minTime?: Date | string | number;
  maxTime?: Date | string;
};

const MUIDatePicker = ({
  type = 'date',
  pickerType = 'date',
  label,
  monthsShown = 1,
  timeIntervals = 15,
  showMonthDropdown = true,
  showYearDropdown = true,
  popperPlacement,
  form,
  field,
  value,
  onChange,
  minDate = new Date(),
  minTime = getTime(new Date()),
  maxTime = endOfDay(new Date()),
  ...rest
}: MUIDatePickerPropTypes) => {
  const [date, setDate] = useState(value || null);
  const [startDate, setStartDate] = useState(value?.['start'] || null);
  const [endDate, setEndDate] = useState(value?.['end'] || null);

  useEffect(() => {
    setStartDate(value?.['start'] || '');
    setEndDate(value?.['end'] || '');
    setDate(value || '');
  }, [value]);

  const handleOnChange = (dates) => {
    let selectedDate;
    let dateRangString;
    if (type === 'range') {
      let [start = '', end = ''] = dates || [];
      if (pickerType !== 'date') {
        start = start ? startOfMonth(start) : '';
        end = end ? endOfMonth(end) : '';
      }
      selectedDate = `${start}${end ? ':' : ''}${end || ''}`;
      dateRangString = `${start ? dayjs(start).format('YYYY-MM-DD') : ''}${
        end ? `:${dayjs(end).format('YYYY-MM-DD')}` : ''
      }`;
      setStartDate(start);
      setEndDate(end);
      onChange && onChange([start, end] || [], dateRangString);
      field?.name?.includes(':') &&
        field?.name?.split(':')?.forEach((fieldName, index) => {
          form && form?.setFieldValue(fieldName, index === 0 ? start : end);
        });
    } else {
      selectedDate = dates;
      setDate(dates);
      onChange && onChange(dates || []);
      form && form?.setFieldValue(field?.name, selectedDate);
    }
  };

  const CustomInput = forwardRef((props: any, ref) => {
    let startDate, endDate, date;
    if (type === 'range') {
      startDate = props?.['start'] ? format(props?.['start'], 'dd MMM yyyy') : '';
      endDate = props?.['end'] ? format(props?.['end'], 'dd MMM yyyy') : '';
    } else {
      props?.['date']
        ? (date = format(
            props?.['date'],
            type === 'time' ? 'h:mm aa' : type === 'date-time' ? 'dd MMM yyyy h:mm aa' : 'dd MMM yyyy',
          ))
        : '';
    }
    const value =
      type === 'range' ? (startDate || endDate) && `${startDate || ''} ${endDate ? '-' : ''} ${endDate || ''}` : date;
    return (
      <MUITextField {...props} disabled={rest?.['disabled']} inputRef={ref} value={value} StartIcon={DateRangeIcon} />
    );
  });

  return (
    <DatePickerWrapper>
      <div>
        <DatePicker
          {...field}
          timeCaption={
            <strong style={{ fontSize: 'var(--fs-base__two)' }} className="d-block">
              Time
            </strong>
          }
          isClearable={type === 'range' ? endDate || startDate : date}
          fixedHeight
          useWeekdaysShort
          showTimeSelect={type === 'time' || type === 'date-time' ? true : false}
          showTimeSelectOnly={type === 'time' ? true : false}
          timeFormat="h:mm aa"
          timeIntervals={timeIntervals}
          selectsRange={type === 'range' ? true : false}
          monthsShown={monthsShown}
          endDate={type === 'range' ? endDate : false}
          startDate={type === 'range' ? startDate : false}
          selected={type === 'range' ? startDate : date}
          id={type === 'range' ? 'date-range-picker' : 'date-range-picker-months'}
          onChange={handleOnChange}
          shouldCloseOnSelect={type === 'range' ? false : true}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          popperPlacement={popperPlacement}
          yearDropdownItemNumber={6}
          useShortMonthInDropdown
          showMonthYearPicker={pickerType === 'month' ? true : false}
          showYearPicker={pickerType === 'year' ? true : false}
          showQuarterYearPicker={pickerType === 'quarter' ? true : false}
          showFourColumnMonthYearPicker={pickerType !== 'date' ? true : false}
          minDate={minDate}
          minTime={minTime}
          maxTime={maxTime}
          customInput={
            type === 'range' ? (
              <CustomInput
                label={label}
                start={startDate as Date | null | string}
                end={endDate as Date | null | string}
                form={form}
                field={field}
                name={rest?.['name']}
              />
            ) : (
              <CustomInput label={label} date={date as Date | null | string} />
            )
          }
          {...rest}
        />
      </div>
    </DatePickerWrapper>
  );
};
export default MUIDatePicker;
