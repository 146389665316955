import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const invoiceApplySchema = yup.object().shape({
  to_company_id: yup.number().required('Please select Bank').positive('Please select Bank'),
  delivery_id: yup.number().required('Please select Invoice').positive('Please select Invoice'),
});

export const dealApplySchema = yup.object().shape({
  to_company_id: yup.number().required('Please select Bank').positive('Please select Bank'),
  deal_id: yup.number().required('Please select Invoice').positive('Please select Invoice'),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (params) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchLedgerDocument = async (params) => {
  try {
    let res = await api.fetchLedgerDocument(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const create = async (params) => {
  try {
    let res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (params) => {
  try {
    let res = await api.update(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDealsList = async (params) => {
  try {
    let res = await api.fetchDealsList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchFinanceList = async (params) => {
  try {
    let res = await api.fetchFinanceList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCreditUtilization = async (params) => {
  try {
    let res = await api.fetchCreditUtilization(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUserFinanceList = async (params) => {
  try {
    let res = await api.fetchUserFinanceList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchBankList = async (params) => {
  try {
    let res = await api.fetchBankList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createInvoiceApply = async (params) => {
  try {
    if (params?.deal_id) {
      await helpers.validateSchema(dealApplySchema, params);
    } else {
      await helpers.validateSchema(invoiceApplySchema, params);
    }
    let res = await api.createInvoiceApply(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createConfirm = async (params) => {
  try {
    let res = await api.createConfirm(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const RejectDeal_CT = async (params) => {
  try {
    let res = await api.RejectDeal_CT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const applyEarlyPaymentOffer = async (params) => {
  try {
    let res = await api.applyEarlyPaymentOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const allowEarlyPaymentCT = async (params) => {
  try {
    let res = await api.allowEarlyPaymentCT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const addManualDeliveries = async (params) => {
  try {
    let res = await api.addManualDeliveries(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const convertToBioFuelCircleTransport = async (params) => {
  try {
    let res = await api.convertToBioFuelCircleTransport(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const checkDealLinkingApplicability = async (params) => {
  try {
    let res = await api.checkDealLinkingApplicability(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const attachDealToIndentBuy = async (params) => {
  try {
    let res = await api.attachDealToIndentBuy(params);
    return res;
  } catch (err) {
    throw err;
  }
};
