import moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import MUITextField from 'common/MUIComponents/TextField';
import { DELIVERY_STATUS_LIST } from '../../../constants/deliveryConstants'

const ConfirmDeleteModal = (props) => {
  const {
    farmDetails,
    entityId,
    closeModal,
    color,
    btn,
    title,
    message,
    colored,
    header,
    rtl,
    backLink,
    handleConfirm,
    isDeliverityRevertReason,
    generateDeliveryRevertControls,
    isMoveDeliveryDate,
    setMoveDeliveriesDateByDays,
    setPickUpTime,
    pickUpTime,
    moveDeliveriesReasonEnum,
    setMoveDeliveriesReasonEnum
  } = props;

  const currentDate = moment();

  const [loading, setLoading] = useState(false);
  const [noOfDays, setNoOfDays] = useState(1);

  const [deliveryRevertReason, setDeliveryRevertReason] = useState('');
  const [deliveryStatusToRevert, setDeliveryStatusToRevert] = useState('');

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  let disabled = false;
  if (isMoveDeliveryDate) {
    disabled = true
    if (noOfDays && moveDeliveriesReasonEnum) {
      disabled = false
    }
  }
  if (isDeliverityRevertReason) {
    disabled = true
    if (deliveryRevertReason && deliveryStatusToRevert) {
      disabled = false
    }
  }
  return (
    <div>
      <div className="d-none">
        <Button color={color} onClick={closeModal}>
          {btn}
        </Button>
      </div>
      <Modal
        modalClassName="ltr-support theme-light"
        isOpen={entityId}
        toggle={closeModal}
      // modalClassName={`ltr-support confirm-delete-modal`}
      // className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title mb-0">{title}</h4>
        </div>
        {/* <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeModal} />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div> */}
        <div className="modal__body bfc-modal mt-15">
          {!isDeliverityRevertReason && (<p className='pl-20 pr-20'>{message || ''}</p>)}
          {isDeliverityRevertReason && (
            <div className="row">
              <div className="col">
                <div className="col-md-12 mt-2">
                  <div className='text-left mb-2'>
                    <span className="form-group-label fw-700">Reason</span>
                    <span class="required">*</span>
                  </div>
                  <MUITextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Please enter reason"
                    value={deliveryRevertReason}
                    onChange={(value) => setDeliveryRevertReason(value)}
                  />
                </div>
                <div className="col-md-12 mt-15">
                  <div className='text-left mb-2'>
                    <span className="form-group-label fw-700">Revert Status</span>
                    <span class="required">*</span>
                  </div>
                  <MUIAutocomplete
                    fullWidth
                    placeholder="Please select revert status"
                    data={DELIVERY_STATUS_LIST}
                    dataStructure={{
                      labelApiParam: 'label',
                      valueApiParam: 'value',
                    }}
                    apiParams={{}}
                    onChange={(newValue) => {
                      setDeliveryStatusToRevert(newValue?.label);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {isMoveDeliveryDate && (
            <div className="row">
              <div className="col">
                <div className="col-md-12">
                  <div className='text-left mb-2'>
                    <span className="form-group-label fw-700">By No Of Days</span>
                    <span class="required">*</span>
                  </div>
                  <TextField
                    type={'number'}
                    size="small"
                    fullWidth
                    value={noOfDays}
                    placeholder={'Please enter no. of days'}
                    variant={'outlined'}
                    onChange={(e) => {
                      let days = (e.target.value >= 1) ? e.target.value : ''
                      if (days > 100) {
                        days = 100;
                      }
                      const futurePickUpDate = currentDate.add(days, 'days');
                      const futurePickUpDateString = futurePickUpDate.toISOString();
                      setPickUpTime(futurePickUpDateString)
                      setNoOfDays(days)
                      setMoveDeliveriesDateByDays(days)
                    }}
                  />
                </div>
                <div className="col-md-12 mt-15">
                  <div className='text-left mb-2'>
                    <span className="form-group-label fw-700">Reason</span>
                    <span class="required">*</span>
                  </div>
                  <MUIAutocomplete
                    fullWidth
                    placeholder="Please select reason"
                    apiParams={{
                      reqUrl: `BioFuelRegistration/api/ReferenceData/GetEnumerator`,
                      method: 'GET',
                      isQueryParams: true,
                      getReqParams: (value) => ({ Enum_Group: 'ReasonTypeEnum' }),
                    }}
                    data={[]}
                    dataStructure={{
                      labelApiParam: 'display_member',
                      valueApiParam: 'value_member',
                    }}
                    onChange={(result) => {
                      setMoveDeliveriesReasonEnum(result?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal__footer mb-20 d-flex justify-content-center pl-20 pr-20">
          <Button disabled={loading} className="modal_cancel btn-sm w-100 mr-10" onClick={closeModal}>
            Cancel
          </Button>{' '}
          <Button
            disabled={disabled}
            className="modal_ok w-100 btn-sm"
            outline={colored}
            color={color}
            onClick={() => {
              if (isDeliverityRevertReason) {
                handleConfirm(deliveryRevertReason, deliveryStatusToRevert)
              } else {
                handleConfirm(props.handleValues, props.handleFormik);
              }
            }}>
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}{' '}
            {loading ? `Processing...` : `Yes, I'm Sure`}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmDeleteModal;
